function checkIfBrowser() {
  return typeof window !== "undefined";
}

// eslint-disable-next-line no-undef
const ENV = checkIfBrowser() ? window.env : process.env;

export const ZBNI_WEBSITE_URL = ENV.REMIX_PUBLIC_ZBNI_WEBSITE_URL;
export const ZBNI_APP_STORE_URL = ENV.REMIX_PUBLIC_ZBNI_APP_STORE_URL;
export const ZBNI_PLAY_STORE_URL = ENV.REMIX_PUBLIC_ZBNI_PLAY_STORE_URL;
export const ZBNI_PHONE_NUMBER_UAE = ENV.REMIX_PUBLIC_PHONE_NUMBER_UAE;
export const ZBNI_PHONE_NUMBER_KSA = ENV.REMIX_PUBLIC_PHONE_NUMBER_KSA;
export const ZBNI_PHONE_NUMBER_JORDAN = ENV.REMIX_PUBLIC_PHONE_NUMBER_JORDAN;
export const ZBNI_PHONE_NUMBER_EGYPT = ENV.REMIX_PUBLIC_PHONE_NUMBER_EGYPT;
export const ZBNI_PHONE_NUMBER_HONGKONG =
  ENV.REMIX_PUBLIC_PHONE_NUMBER_HONGKONG;
export const ZBNI_MEYDAN_API_ENDPOINT = ENV.REMIX_PUBLIC_MEYDAN_API_ENDPOINT;
export const ZBNI_SHAPES_DEFINED_API_ENDPOINT =
  ENV.REMIX_PUBLIC_SHAPES_DEFINED_API_ENDPOINT;
export const ZBNI_LEAD_GEN_ZAPIER_ENDPOINT =
  ENV.REMIX_PUBLIC_LEAD_GEN_ZAPIER_ENDPOINT;
export const ZBNI_CCOMMERCE_API_ENDPOINT =
  ENV.REMIX_PUBLIC_CCOMMERCE_REPORT_API_ENDPOINT;
export const ZBNI_RECAPTCHA_SITE_KEY = ENV.REMIX_PUBLIC_RECAPTCHA_SITE_KEY;
export const ZBNI_BACKEND_API_ENDPOINT = ENV.REMIX_PUBLIC_BACKEND_API_ENDPOINT;
export const ZBNI_BACKEND_CLIENT_ID = ENV.REMIX_PUBLIC_BACKEND_CLIENT_ID;
export const ZBNI_BACKEND_CLIENT_SECRET =
  ENV.REMIX_PUBLIC_BACKEND_CLIENT_SECRET;
export const ZBNI_BACKEND_GRANT_TYPE = ENV.REMIX_PUBLIC_BACKEND_GRANT_TYPE;
export const ZBNI_CUSTOMER_SUPPORT =
  "https://api.whatsapp.com/send?phone=971555928787";
export const ZBNI_CSHOP_MARKET_URL = ENV.REMIX_PUBLIC_CSHOP_MARKET_URL;
export const ZBNI_SUPPORT_MARKETING_URL =
  ENV.REMIX_PUBLIC_SUPPORT_MARKETING_URL;
export const ZBNI_HUB_URL = ENV.REMIX_PUBLIC_ZBOONI_HUB_URL;

export const SENTRY_DSN = ENV.REMIX_PUBLIC_SENTRY_DSN;
export const SENTRY_TEST_KEY = ENV.SENTRY_TEST_KEY;
export const ZBOONI_ANDROID_PACKAGE_NAME = ENV.ZBOONI_ANDROID_PACKAGE_NAME;
export const ZBOONI_IOS_APP_ID = ENV.ZBOONI_IOS_APP_ID;
